.vc-container {
  height: calc(100% - 2.7em); // height of axis
}

.vc-canvas {
  position: relative;
  width: 100%;
  height: 100%;
}

.vc-layer-canvas {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.vc-layer-div {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  overflow: hidden;
}

.vc-tooltip {
  display: inline-block;
  z-index: 3000;
  position: absolute;
  font-size: 0.85em;
  padding: 4px 6px;
  border-radius: 2px;
  white-space: nowrap;
  background: rgba(#fff, 0.9);
  color: #333;
  opacity: 0;
  transition: opacity 200ms ease-in;

  &.visible {
    opacity: 1;
    transition: opacity 200ms ease-out;
  }
}

.vc-content-item-description {
  position: absolute;
  top: 0;
  left: 0;
  background-color: transparent;
  color: rgb(232, 232, 232);
  font-size: 10px;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;

  .text {
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    height: inherit;
  }
}

.unselectable {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
