@import 'normalize.css';

@import './axis';
@import './vc';

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -webkit-overflow-scrolling: touch;
}

html, body {
  width: 100vw;
  height: 100vh;
  font-size: 16px;
  font-family: Helvetica, sans-serif;
  touch-action: none;
}

main {
  position: relative;
  width: 100%;
  height: calc(100% - 3em);
  background: #fff;
  color: #333;
}

header {
  display: flex;
  align-items: center;
  padding: 0.25em 1em;
  height: 3em;

  img {
    height: 100%;
    margin-right: 2em;
    object-fit: contain;
  }

  div {
    margin-right: 1em;
    cursor: pointer;
  }
}

a, .link {
  text-decoration: none;
  color: #2d84da;
  border-bottom: 1px solid rgba(45, 132, 218, 0.5);

  &:hover {
    border-bottom-color: rgba(45, 132, 218, 0.85);
  }
}

.unselectable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.languages {
  display: flex;
  margin-left: auto;
  font-size: 0.85em;

  span {
    display: inline-block;
    margin: 0 0.4em;

    &.active {
      color: #333;
      border-bottom-color: transparent;
      cursor: pointer;
      pointer-events: none;
    }
  }
}
