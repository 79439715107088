.axis {
  position: relative;
}

.cz-timescale {
  position: relative;
  width: 100%;
  height: 3.5em;
  padding: 0;

  font-size: 0.75em;
  cursor: default;

  background-color: #fff;
  color: #333;

  canvas {
    position: absolute;
    bottom: 0;
  }

  .cz-timescale-labels-container {
    position: absolute;
    bottom: 2.8em;

    .cz-timescale-label {
      position: absolute;
      display: block;
      white-space: nowrap;
    }
  }

  .cz-timescale-marker {
    position: absolute;
    z-index: 999;
    height: 100%;
    top: 0;
    left: 0;
    background-color: transparent;
    transform: translateX(-1000px);

    .cz-timescale-marker-text {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 1.6em;
      padding: 0 0.6em;
      font-size: 1.1em;
      border-radius: 4px;
      color: #fff;
      background-color: rgba(#333, 0.8);
      white-space: nowrap;
    }

    .cz-timescale-marker-triangle {
      position: absolute;
      width: 0;
      height: 0;
      bottom: 0;
      left: 50%;

      border-top: 1.75em solid rgba(#333, 0.8);
      border-left: 0.8em solid transparent;
      border-right: 0.8em solid transparent;

      margin-left: -0.8em;
    }
  }
}
